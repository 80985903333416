import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'

import { indexItemsSelector, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import SearchKeywordCountListComponent from 'components/search/SearchKeywordCountListComponent'

const StandardListComponent: React.FC = () => {
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const oneDepthItems = useRecoilValue(indexItemsSelector)
  const [selectedOneDepthIndexItem, setSelectedOneDepthIndexItem] = useRecoilState(
    selectedOneDepthIndexItemAtom
  )

  const makeStandardListItem = () => {
    const components: JSX.Element[] = _.map(oneDepthItems.index, (indexItem) => {
      const isSelected = indexItem.id === selectedOneDepthIndexItem?.id
      return (
        <Link key={`StandardListItem-${selectedStandardId}-${indexItem.id}`} to="/">
          <div
            className={isSelected ? 'SelectedStandardListItemFrame' : 'StandardListItemFrame'}
            onClick={() => {
              setSelectedOneDepthIndexItem(indexItem)
            }}
          >
            {isSelected && <div className="SelectedLeftSideBar" />}
            <div className={isSelected ? 'SelectedStandardListItem' : 'StandardListItem'}>
              <div className="StandardListItemTextFrame">
                <div className="StandardListItemText SelectedStandardListItemText">
                  {indexItem.name}
                </div>
              </div>
              <SearchKeywordCountListComponent indexItemId={indexItem.id} itemArea="TitleItem" />
            </div>
          </div>
        </Link>
      )
    })

    return components
  }

  return <div>{makeStandardListItem()}</div>
}

export default StandardListComponent
