import { atom, selector } from 'recoil'

import { Standard, getStandards } from 'services/standard_client'

export const standardsSelector = selector<Standard[]>({
  key: 'standardsSelector',
  get: async () => {
    return (await getStandards()).standards
  }
})

export const standardIdsSelector = selector<string[]>({
  key: 'standardIdsSelector',
  get: ({ get }) => {
    const standards = get(standardsSelector)

    return standards.map((standard) => standard.id)
  }
})

export const selectedStandardIdAtom = atom<string>({
  key: 'selectedStandardIdAtom',
  default: 'ASNPO'
})

export const isOpenStandardTableListDropdownAtom = atom<boolean>({
  key: 'isOpenStandardTableListDropdownAtom',
  default: false
})
