import React from 'react'

import TableListHeaderComponent from 'components/header/TableListHeaderComponent'
import TableListContentAreaComponent from 'components/tabletitle/TableListContentAreaComponent'

const TableListComponent: React.FC = () => {
  return (
    <div className="TableTitleArea">
      <TableListHeaderComponent />
      <TableListContentAreaComponent />
    </div>
  )
}

export default TableListComponent
