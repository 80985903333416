import _ from 'lodash'
import { atom, selector, selectorFamily } from 'recoil'

import { SearchCount, loadAutoCompleteKeywords } from 'services/standard_client'

export const SEARCH_DELETE_ICON_COLOR_LIST = [
  '#FFC078',
  '#8CE99A',
  '#E599F7',
  '#66D9E8',
  '#FAA2C1',
  '#FFD43B'
]

export const SEARCH_CONTENT_KEYWORD_COLOR_LIST = [
  '#FFF4E6',
  '#EBFBEE',
  '#F8F0FC',
  '#E3FAFC',
  '#FFF5F5',
  '#FFF9DB'
]

export const SEARCH_TITLE_KEYWORD_COLOR_LIST = [
  '#FFE8CC',
  '#D3F9D8',
  '#F3D9FA',
  '#C5F6FA',
  '#FFDEEB',
  '#FFF3BF'
]

export const isSearchCheckBoxSelectedAtom = atom<boolean>({
  key: 'isSearchCheckBoxSelectedAtom',
  default: true
})

// key : search keyword string
// value : color list index
export const searchStandardColorMapAtom = atom<Map<string, number>>({
  key: 'searchStandardColorMapAtom',
  default: new Map()
})

// key : search keyword string
// value : search result count array
export const searchStandardCountMapAtom = atom<Map<string, SearchCount[]>>({
  key: 'searchStandardCountMapAtom',
  default: new Map()
})

// string : search keyword
export const searchStandardKeywordAtom = atom<string>({
  key: 'searchStandardKeywordAtom',
  default: ''
})

// string : search keyword
export const searchTotalKeywordAtom = atom<string>({
  key: 'searchTotalKeywordAtom',
  default: ''
})

export const selectedSearchedKeywordAtom = atom<string>({
  key: 'selectedSearchedKeywordAtom',
  default: ''
})

export const maxSearchCountAlertAtom = atom<boolean>({
  key: 'maxSearchCountAlertAtom',
  default: false
})

// setter를 사용하기 위한 selector,
// getter는 사용하지 않음
export const addSearchStandardColorMapSelector = selector({
  key: 'addSearchStandardColorMapSelector',
  get: () => {
    return ''
  },
  set: ({ set, get }, keyword) => {
    if (!keyword || typeof keyword !== 'string') {
      return
    }
    const searchStandardColorMap = get(searchStandardColorMapAtom)

    if (searchStandardColorMap.size >= 6) {
      return
    }

    const newColorMap = _.cloneDeep(searchStandardColorMap)
    const colorListIndex = [0, 1, 2, 3, 4, 5]
    const newColorListIndex = _.difference(colorListIndex, Array.from(newColorMap.values()))

    if (newColorListIndex.length === 0) {
      return
    }

    newColorMap.set(keyword, newColorListIndex[0])
    set(searchStandardColorMapAtom, newColorMap)
  }
})

const loadAutoCompleteKeywordsSelector = selector<string[]>({
  key: 'loadAutoCompleteKeywordsSelector',
  get: async () => {
    return (await loadAutoCompleteKeywords()).keywords
  }
})

export const getCandidatesSelector = selectorFamily<string[], string>({
  key: 'getCandidatesSelector',
  get(inputStr) {
    return async function ({ get }) {
      let text = inputStr
      while (text.length >= 1 && /[ㄱ-ㅎㅏ-ㅣ]/.test(text[text.length - 1])) {
        text = text.slice(0, text.length - 1)
      }
      if (text.length === 0) {
        return []
      }

      const keywords = get(loadAutoCompleteKeywordsSelector)
      const candidates = []
      // 1. 입력값으로 시작하는 단어.
      candidates.push(...keywords.filter((keyword) => keyword.startsWith(text)))
      if (text.length === 1) {
        // NOTE: 한글자일 경우, 해당 글자로 시작하는 단어만 출력.
        return candidates
      }
      // 2. 입력값을 포함하는 단어.
      candidates.push(...keywords.filter((keyword) => keyword.includes(text)))
      // 3. 입력값이 단축어 일 경우.
      candidates.push(
        ...keywords.filter((keyword) => {
          let prev = -1
          for (let i = 0; i < text.length; ++i) {
            const idx = keyword.indexOf(text[i])
            if (idx < 0 || prev >= idx) {
              return false
            }
            prev = idx
          }
          return true
        })
      )

      return _.uniq(candidates)
    }
  }
})
