import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import { selectedIndexItemAtom, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import {
  selectedStandardIdAtom,
  isOpenStandardTableListDropdownAtom
} from 'atoms/standard/Standard'
import SearchKeywordCountListComponent from 'components/search/SearchKeywordCountListComponent'
import { useSearchResultExist } from 'hook/search/useSearchResultExist'
import { Index } from 'services/standard_client'

type TableTitleItemComponentProps = {
  indexItem: Index
  callbackFunc?: (event: React.MouseEvent) => void
}

const TableTitleItemComponent: React.FC<TableTitleItemComponentProps> = ({
  indexItem,
  callbackFunc
}) => {
  const navigate = useNavigate()
  const [selectedOneDepthIndexItem, setSelectedOneDepthIndexItem] = useRecoilState(
    selectedOneDepthIndexItemAtom
  )
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const setSelectedIndexItem = useSetRecoilState(selectedIndexItemAtom)
  const isSearchResultExist = useSearchResultExist(indexItem.id)
  const setIsOpenStandardTableListDropdown = useSetRecoilState(isOpenStandardTableListDropdownAtom)

  const handleCallback = (event: React.MouseEvent) => {
    const firstChildIndexItem = indexItem.children[0] ? indexItem.children[0] : indexItem
    setSelectedOneDepthIndexItem(indexItem)
    setSelectedIndexItem(firstChildIndexItem)
    if (callbackFunc) {
      callbackFunc(event)
    }

    setIsOpenStandardTableListDropdown(false)
    navigate(['', 'standard', selectedStandardId, indexItem.id, firstChildIndexItem.id].join('/'))
  }
  return (
    <div
      className={classNames({
        TableTitleItem: true,
        TableTitleSearchResultExist: isSearchResultExist
      })}
      onClick={handleCallback}
    >
      <div
        className={classNames({
          TableTitleId: true,
          SelectedTableTitleItem: indexItem.id === selectedOneDepthIndexItem?.id
        })}
      >
        {indexItem.id}
      </div>
      <div
        className={classNames({
          TableTitleName: true,
          SelectedTableTitleItem: indexItem.id === selectedOneDepthIndexItem?.id
        })}
      >
        {indexItem.name}
      </div>
      <SearchKeywordCountListComponent indexItemId={indexItem.id} itemArea="TitleItem" />
    </div>
  )
}

TableTitleItemComponent.defaultProps = {
  callbackFunc: undefined
}

export default TableTitleItemComponent
