import _ from 'lodash'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { contentItemsSelector } from 'atoms/content/Content'
import { searchStandardColorMapAtom, SEARCH_CONTENT_KEYWORD_COLOR_LIST } from 'atoms/search/Search'
import DescriptionItemComponent from 'components/description/DescriptionItemComponent'

const DescriptionAreaComponent: React.FC = () => {
  const contentItems = useRecoilValue(contentItemsSelector)
  const searchStandardColorMap = useRecoilValue(searchStandardColorMapAtom)
  const searchPatterns: { keyword: string; color: string }[] = React.useMemo(() => {
    return _.map([...searchStandardColorMap.entries()], ([key, value]) => ({
      keyword: key,
      color: SEARCH_CONTENT_KEYWORD_COLOR_LIST[value]
    }))
  }, [searchStandardColorMap])

  const renderDescriptionArea = () => {
    if (!contentItems) {
      return <></>
    }
    return (
      <>
        {_.map(contentItems, (contentItem) => (
          <DescriptionItemComponent
            contentItem={contentItem}
            key={`DescriptionItem-${contentItem.id}`}
            searchPatterns={searchPatterns}
          />
        ))}
        <div style={{ height: 100 }} />
      </>
    )
  }

  return renderDescriptionArea()
}

export default DescriptionAreaComponent
