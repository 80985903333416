import { isEmpty } from '@cck/common'
import React, { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import {
  bookmarkAreaOpenAtom,
  isBookmarkAreaVerticalAtom,
  bookmarkEditingModeAtom,
  bookmarkItemMapAtom,
  BookmarkItemMap
} from 'atoms/bookmark/Bookmark'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import { ReactComponent as BookmarkAreaHorizontal } from 'resource/bookmark_area_horizontal.svg'
import { ReactComponent as BookmarkAreaHorizontalSelected } from 'resource/bookmark_area_horizontal_selected.svg'
import { ReactComponent as BookmarkAreaVertical } from 'resource/bookmark_area_vertical.svg'
import { ReactComponent as BookmarkAreaVerticalSelected } from 'resource/bookmark_area_vertical_selected.svg'

const BookmarkHeaderComponent: React.FC = () => {
  const [isBookmarkAreaVertical, setIsBookmarkAreaVertical] = useRecoilState(
    isBookmarkAreaVerticalAtom
  )

  const [bookmarkEditingMode, setBookmarkEditingMode] = useRecoilState(bookmarkEditingModeAtom)
  const setBookmarkAreaOpen = useSetRecoilState(bookmarkAreaOpenAtom)
  const bookmarkItemMapLodable = useRecoilValueLoadable(bookmarkItemMapAtom)
  const [bookmarkItemMap, setBookmarkItemMap] = useState<BookmarkItemMap>(new Map())
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)

  useEffect(() => {
    if (bookmarkItemMapLodable.state === 'hasValue') {
      setBookmarkItemMap(bookmarkItemMapLodable.contents)
    }
  }, [bookmarkItemMapLodable])

  const makeBookmarkAreaSelectedIcon = () => {
    if (bookmarkEditingMode) {
      return <div className="BookmarkHeaderIconGroupFrame" />
    }

    if (isBookmarkAreaVertical) {
      return (
        <div className="BookmarkHeaderIconGroupFrame">
          <div className="BookmarkAreaVerticalIcon">
            <BookmarkAreaVerticalSelected />
          </div>
          <div
            className="BookmarkAreaHorizontalIcon"
            onClick={() => setIsBookmarkAreaVertical(false)}
          >
            <BookmarkAreaHorizontal />
          </div>
        </div>
      )
    }

    return (
      <div className="BookmarkHeaderIconGroupFrame">
        <div className="BookmarkAreaVerticalIcon" onClick={() => setIsBookmarkAreaVertical(true)}>
          <BookmarkAreaVertical />
        </div>
        <div className="BookmarkAreaHorizontalIcon">
          <BookmarkAreaHorizontalSelected />
        </div>
      </div>
    )
  }

  const closeBookmarkArea = () => {
    setBookmarkAreaOpen(false)
  }

  const completeBookmarkEditing = () => {
    setBookmarkEditingMode(false)
    if (isEmpty(bookmarkItemMap.get(selectedStandardId))) {
      closeBookmarkArea()
    }
  }

  const makeBookmarkEditingButton = () => {
    if (bookmarkEditingMode) {
      return (
        <div
          className="BookmarkHeaderEditTextFrame BookmarkHeaderEditTextEnabled"
          onClick={completeBookmarkEditing}
        >
          완료
        </div>
      )
    }

    if (bookmarkItemMap.size === 0 || isEmpty(bookmarkItemMap.get(selectedStandardId))) {
      return (
        <div className="BookmarkHeaderEditTextFrame BookmarkHeaderEditTextDisabled">
          <span>편집</span>
          <span
            className="BookmarkHeaderButton BookmarkHeaderCloseButton"
            onClick={() => closeBookmarkArea()}
          >
            닫기
          </span>
        </div>
      )
    }

    return (
      <div className="BookmarkHeaderEditTextFrame BookmarkHeaderEditTextEnabled">
        <span className="BookmarkHeaderButton" onClick={() => setBookmarkEditingMode(true)}>
          편집
        </span>
        <span
          className="BookmarkHeaderButton BookmarkHeaderCloseButton"
          onClick={() => closeBookmarkArea()}
        >
          닫기
        </span>
      </div>
    )
  }

  return (
    <div className="BookmarkHeaderFrame">
      {makeBookmarkAreaSelectedIcon()}
      {makeBookmarkEditingButton()}
    </div>
  )
}

export default BookmarkHeaderComponent
