import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { indexItemsSelector, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import MainTableContentComponent from 'components/MainTableContentComponent'
import MainTableListComponent from 'components/MainTableListComponent'

import 'App.sass'

const App: React.FC = () => {
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const [selectedOneDepthIndexItem, setSelectedOndeDepthIndexItem] = useRecoilState(
    selectedOneDepthIndexItemAtom
  )
  const indexItems = useRecoilValue(indexItemsSelector)

  const navigateDefaultUrl = () => {
    let path = `/standard/${selectedStandardId}`
    if (selectedOneDepthIndexItem) {
      path += `/${selectedOneDepthIndexItem.id}`
    } else {
      path += `/${indexItems.index[0].id}`
      setSelectedOndeDepthIndexItem(indexItems.index[0])
    }
    return <Route element={<Navigate replace to={path} />} path="/" />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainTableListComponent />} path="/standard/:standardId" />
        <Route
          element={<MainTableContentComponent />}
          path="/standard/:standardId/:oneDepthIndexItemId/:indexItemId/*"
        />
        <Route
          element={<MainTableContentComponent />}
          path="/standard/:standardId/:oneDepthIndexItemId/*"
        />
        {navigateDefaultUrl()}
      </Routes>
    </BrowserRouter>
  )
}

export default App
