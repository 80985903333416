import { isEmpty } from '@cck/common'
import { cloneDeep } from 'lodash'
import { atom, selector, selectorFamily } from 'recoil'

import { ContentReply, getContent } from 'services/standard_client'

export interface BookmarkContentItem {
  bookmarkName: string
  id: string
}

export interface BookmarkItem {
  oneDepthIndexName: string
  bookmarkContentList: BookmarkContentItem[]
}

// key : oneDepthIndexId
export type OneDepthBookmarkItemMap = Map<string, BookmarkItem>

// key : standardId
export type BookmarkItemMap = Map<string, OneDepthBookmarkItemMap>

export interface RemoveBookmarkInfo {
  standardId: string
  oneDepthIndexId: string
  bookmarkName?: string
}

export interface RemoveOneDepthBookmarkInfo {
  standardId: string
  oneDepthIndexId: string
}

export interface AddBookmarkInfo {
  standardId: string
  oneDepthIndexId: string
  oneDepthIndexName: string
  bookmarkContentItemList: BookmarkContentItem[]
  oneDepthSortedIdList: string[]
}

export const verticalBookmarkAreaWidthAtom = atom<number>({
  key: 'verticalBookmarkAreaWidthAtom',
  default: 300
})

export const horizontalBookmarkAreaHeightAtom = atom<number>({
  key: 'horizontalBookmarkAreaHeightAtom',
  default: 300
})

export const lastAddedBookmarkNameAtom = atom<string>({
  key: 'lastAddedBookmarkNameAtom',
  default: ''
})

export const bookmarkDuplicateAddAlertAtom = atom<boolean>({
  key: 'bookmarkDuplicateAddAlertAtom',
  default: false
})

export const bookmarkItemMapAtom = atom<BookmarkItemMap>({
  key: 'bookmarkItemMapAtom',
  default: new Map()
})

export const selectedBookmarkStandardIdAtom = atom<string>({
  key: 'selectedBookmarkStandardIdAtom',
  default: ''
})

// value : bookmark toggle state. true - open, false - close
export const bookmarkAreaOpenAtom = atom<boolean>({
  key: 'bookmarkAreaOpenAtom',
  default: false
})

export const isBookmarkAreaVerticalAtom = atom<boolean>({
  key: 'isBookmarkAreaVerticalAtom',
  default: false
})

// value : bookmark editing mode state
export const bookmarkEditingModeAtom = atom<boolean>({
  key: 'bookmarkEditingModeAtom',
  default: false
})

export const removeBookmarkItemMapSelector = selector({
  key: 'removeBookmarkItemMapSelector',
  get: () => {
    return {}
  },
  set: ({ set, get }, removeBookmarkInfo) => {
    if (!removeBookmarkInfo) {
      return
    }

    const removeBookmarkInfoObject = removeBookmarkInfo as RemoveBookmarkInfo
    const standardId = removeBookmarkInfoObject.standardId
    const oneDepthIndexId = removeBookmarkInfoObject.oneDepthIndexId
    const bookmarkName = removeBookmarkInfoObject.bookmarkName

    const bookmarkItemMap = get(bookmarkItemMapAtom)
    const oneDepthBookmarkItemMap = bookmarkItemMap.get(standardId)
    if (isEmpty(oneDepthBookmarkItemMap)) {
      return
    }

    const bookmarkItem = oneDepthBookmarkItemMap.get(oneDepthIndexId)
    if (isEmpty(bookmarkItem)) {
      return
    }

    const newBookmarkItemMap = cloneDeep(bookmarkItemMap)
    // NOTE
    // 이후 로직 condition depth 줄이도록 수정 필요

    // remove oneDepth
    if (!bookmarkName) {
      set(removeOneDepthBookmarkItemSelector, { standardId, oneDepthIndexId })
      return
    }

    // remove oneDepth bookmarkItem
    const removeTargetIndex = bookmarkItem.bookmarkContentList.findIndex(
      (bookmarkContentItem) => bookmarkContentItem.bookmarkName === bookmarkName
    )

    if (removeTargetIndex < 0) {
      return
    }

    bookmarkItem.bookmarkContentList.splice(removeTargetIndex, 1)
    if (bookmarkItem.bookmarkContentList.length === 0) {
      set(removeOneDepthBookmarkItemSelector, { standardId, oneDepthIndexId })
      return
    }

    oneDepthBookmarkItemMap.set(oneDepthIndexId, bookmarkItem)
    newBookmarkItemMap.set(standardId, oneDepthBookmarkItemMap)
    set(selectedBookmarkStandardIdAtom, standardId)
    set(bookmarkItemMapAtom, newBookmarkItemMap)
  }
})

export const getBookmarkContentItemSelectorFamily = selectorFamily<
  ContentReply,
  { standardId: string; id: string }
>({
  key: 'getBookmarkContentItemSelectorFamily',
  get({ standardId, id }) {
    return async function () {
      const response = await getContent(standardId, id)
      return response
    }
  }
})

const removeOneDepthBookmarkItemSelector = selector({
  key: 'removeOneDepthBookmarkItemSelector',
  get: () => {
    return {}
  },
  set: ({ set, get }, removeOneDepthBookmarkInfo) => {
    if (!removeOneDepthBookmarkInfo) {
      return
    }

    const removeBookmarkInfoObject = removeOneDepthBookmarkInfo as RemoveOneDepthBookmarkInfo
    const standardId = removeBookmarkInfoObject.standardId
    const oneDepthIndexId = removeBookmarkInfoObject.oneDepthIndexId

    const bookmarkItemMap = get(bookmarkItemMapAtom)
    const oneDepthBookmarkItemMap = bookmarkItemMap.get(standardId)

    if (isEmpty(oneDepthBookmarkItemMap)) {
      return
    }
    oneDepthBookmarkItemMap.delete(oneDepthIndexId)
    const newBookmarkItemMap = cloneDeep(bookmarkItemMap)

    if (isEmpty(oneDepthBookmarkItemMap)) {
      newBookmarkItemMap.delete(standardId)
      if (newBookmarkItemMap.size > 0) {
        const [firstKey] = newBookmarkItemMap.keys()
        set(selectedBookmarkStandardIdAtom, firstKey)
      } else {
        set(selectedBookmarkStandardIdAtom, '')
      }
    } else {
      newBookmarkItemMap.set(standardId, oneDepthBookmarkItemMap)
    }

    set(bookmarkItemMapAtom, newBookmarkItemMap)
  }
})
