import { isEmpty } from '@cck/common'
import React, { useEffect } from 'react'

type TotalSearchAutoKeywordComponentProps = {
  candidatesKeywords: string[]
  searchInputKeyword: string
  setCandidates: (candidates: string[]) => void
  handleSearchKeyword: (searchTargetKeyword: string) => void
}

const TotalSearchAutoKeywordComponent: React.FC<TotalSearchAutoKeywordComponentProps> = ({
  candidatesKeywords,
  searchInputKeyword,
  setCandidates,
  handleSearchKeyword
}) => {
  const closeAutoKeywordDropdown = (event: MouseEvent) => {
    const targetElement = event.target as Element
    if (
      targetElement.closest('.AutoCompleteKeywordsFrame') ||
      targetElement.closest('.TotalSearchInput')
    ) {
      return
    }

    setCandidates([])
  }

  useEffect(() => {
    document.addEventListener('click', closeAutoKeywordDropdown)

    return () => {
      document.removeEventListener('click', closeAutoKeywordDropdown)
    }
  })

  const makeKeywordTextItem = (keyword: string, searchKeyword: string) => {
    if (!keyword.includes(searchKeyword)) {
      return <span>{keyword}</span>
    }

    const components: JSX.Element[] = []
    let findIndex = 0
    let startIndex = 0
    while (startIndex < keyword.length) {
      findIndex = keyword.indexOf(searchKeyword, startIndex)
      if (findIndex < 0) {
        components.push(<span>{keyword.slice(startIndex)}</span>)
        return components
      }

      components.push(<span>{keyword.slice(startIndex, findIndex)}</span>)
      components.push(
        <span className="AutoCompleteKeywordMatch">
          {keyword.slice(findIndex, findIndex + searchKeyword.length)}
        </span>
      )

      startIndex = findIndex + searchKeyword.length
    }

    return components
  }

  const makeAutoCompleteKeywords = () => {
    if (isEmpty(candidatesKeywords) || searchInputKeyword === '') {
      return null
    }

    const renderComponents: JSX.Element[] = []
    candidatesKeywords.forEach((keyword) =>
      renderComponents.push(
        <div className="AutoCompleteKeywordText" onClick={() => handleSearchKeyword(keyword)}>
          {makeKeywordTextItem(keyword, searchInputKeyword)}
        </div>
      )
    )

    return (
      <div className="AutoCompleteKeywordsFrame">
        <div className="AutoCompleteKeywordsPlaceHolderText">추천 검색어</div>
        {renderComponents}
      </div>
    )
  }

  return makeAutoCompleteKeywords()
}

export default TotalSearchAutoKeywordComponent
