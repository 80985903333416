import React, { Suspense } from 'react'

import TableContentComponent from 'components/TableContentComponent'

const MainTableContentComponent: React.FC = () => {
  return (
    <Suspense>
      <TableContentComponent />
    </Suspense>
  )
}

export default MainTableContentComponent
