import { isEmpty } from '@cck/common'
import { Button, Modal } from 'antd'
import parse, { domToReact } from 'html-react-parser'
import _ from 'lodash'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { getBookmarkContentItemSelectorFamily } from 'atoms/bookmark/Bookmark'
import { ReactComponent as CloseTableIcon } from 'resource/bookmark/bookmark_close_table.svg'
import { ReactComponent as ShowTableIcon } from 'resource/bookmark/bookmark_show_table.svg'
import { isTag, updateImageSrc } from 'utils/html_utils'

type BookmarkContentItemComponentProps = {
  standardId: string
  contentId: string
}

const BookmarkContentItemComponent: React.FC<BookmarkContentItemComponentProps> = ({
  standardId,
  contentId
}) => {
  const [isTableOpens, setIsTableOpens] = React.useState<Record<string, boolean>>({})

  const getBookmarkContentItem = useRecoilValue(
    getBookmarkContentItemSelectorFamily({ standardId, id: contentId })
  )

  const bookmarkCloseIcon = (
    <Button className="BookmarkCloseTable" type="text">
      <CloseTableIcon /> <span>닫기</span>
    </Button>
  )

  const replaceTableToButton = (htmlText: string) => {
    return parse(htmlText, {
      replace: (node) => {
        const image = updateImageSrc(node)
        if (image) {
          return <>{image}</>
        }
        if (
          isTag(node) &&
          node.attribs &&
          node.attribs.id === 'XL' &&
          !_.has(node.attribs, 'data-onerow')
        ) {
          const name = _.get(node.attribs, 'data-tableid', '테이블 보기')
          const readonlyName = name.replace(/^.*p\.\d+(?:~\d+)?\s+(.*)/, '$1')

          return (
            <>
              <Button
                className="BookmarkShowTable"
                type="text"
                onClick={() => {
                  const newIsTableOpens = _.cloneDeep(isTableOpens)
                  newIsTableOpens[name] = true
                  setIsTableOpens(newIsTableOpens)
                }}
              >
                <ShowTableIcon /> <span>{readonlyName}</span>
              </Button>
              <Modal
                className="BookmarkTableModal"
                closeIcon={bookmarkCloseIcon}
                footer={null}
                visible={isTableOpens[name]}
                onCancel={() => {
                  const newIsTableOpens = _.cloneDeep(isTableOpens)
                  newIsTableOpens[name] = false
                  setIsTableOpens(newIsTableOpens)
                }}
              >
                <div style={{ height: 40 }} />
                {domToReact([node])}
              </Modal>
            </>
          )
        }
      }
    })
  }

  const renderBookmarkContentItem = () => {
    const content = getBookmarkContentItem
    if (isEmpty(content) || isEmpty(content.content)) {
      return null
    }

    const converted = replaceTableToButton(content.content[0].content)
    return <div className="BookmarkContentText">{converted}</div>
  }

  return renderBookmarkContentItem()
}

export default BookmarkContentItemComponent
