import React from 'react'
import { useRecoilValue } from 'recoil'

import { indexItemsSelector } from 'atoms/index/Index'
import TableTitleItemComponent from 'components/tabletitle/TableTitleItemComponent'

type TableTitleColumnAreaComponentProps = {
  columnOrder: number
}

const TableTitleColumnAreaComponent: React.FC<TableTitleColumnAreaComponentProps> = ({
  columnOrder
}) => {
  const indexItems = useRecoilValue(indexItemsSelector)

  const renderTableList = () => {
    const components: JSX.Element[] = []

    const indexLength = indexItems.index.length
    const quotient = Math.floor(indexLength / 4)
    const remainder = indexLength % 4
    const adjustValue = remainder > columnOrder ? 1 : 0
    const startIndex = quotient * columnOrder + adjustValue * columnOrder
    const endIndex = startIndex + quotient + adjustValue

    for (let index = startIndex; index < endIndex; index += 1) {
      const indexItem = indexItems.index[index]
      components.push(<TableTitleItemComponent indexItem={indexItem} key={indexItem.id} />)
    }

    return components
  }

  return <div className="TableTitleColumnFrame">{renderTableList()}</div>
}

export default TableTitleColumnAreaComponent
