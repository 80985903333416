import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

type PortalComponentProps = {
  children?: ReactNode
  parentElement?: HTMLElement
}

const PortalComponent: React.FC<PortalComponentProps> = ({ children, parentElement }) => {
  const targetElement = parentElement ?? document.querySelector('.AppRoot') ?? document.body
  return ReactDOM.createPortal(children, targetElement)
}

export default PortalComponent
