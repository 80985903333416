import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { selectedIndexItemAtom, selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import SearchKeywordCountListComponent from 'components/search/SearchKeywordCountListComponent'
import { useSearchResultExist } from 'hook/search/useSearchResultExist'
import { ReactComponent as LevelFourBullet } from 'resource/content/content_item_list_circle.svg'
import { ReactComponent as LevelTreeBullet } from 'resource/content/content_item_list_square.svg'
import { Index } from 'services/standard_client'

type ContentItemComponentProps = {
  currentIndexItem?: Index
  listViewDepth?: number
}

const ContentItemComponent: React.FC<ContentItemComponentProps> = ({
  currentIndexItem,
  listViewDepth
}) => {
  const [selectedIndexItem, setSelectedIndexItem] = useRecoilState(selectedIndexItemAtom)
  const isSearchResultExist = useSearchResultExist(currentIndexItem?.id)
  const selectedStandardId = useRecoilValue(selectedStandardIdAtom)
  const selectedOneDepthIndexItem = useRecoilValue(selectedOneDepthIndexItemAtom)

  const navigate = useNavigate()

  const handleItemClick = () => {
    if (!currentIndexItem) {
      return
    }

    setSelectedIndexItem(currentIndexItem)

    if (!selectedOneDepthIndexItem) {
      return
    }

    navigate(
      ['', 'standard', selectedStandardId, selectedOneDepthIndexItem.id, currentIndexItem.id].join(
        '/'
      )
    )
  }

  const makeSearchCountItems = () => {
    if (!isSearchResultExist) {
      return null
    }

    if (!currentIndexItem) {
      return null
    }

    return (
      <SearchKeywordCountListComponent indexItemId={currentIndexItem.id} itemArea="ContentItem" />
    )
  }

  const makeBullet = () => {
    switch (listViewDepth) {
      case 1:
      case 2:
        return null
      case 3:
        return (
          <span className="ContentLevelBullet">
            <LevelTreeBullet />
          </span>
        )
      case 4:
        return (
          <span className="ContentLevelBullet">
            <LevelFourBullet />
          </span>
        )
      case 5:
        return <span>-</span>
      default:
        return null
    }
  }

  const renderContentItem = () => {
    if (!currentIndexItem) {
      return null
    }

    const contentClassName = 'ContentItemLevel-' + listViewDepth
    return (
      <div>
        <div
          className={classNames({
            [`${contentClassName}`]: true,
            SelectedContentItem: selectedIndexItem?.id === currentIndexItem.id,
            SearchResultExist: isSearchResultExist
          })}
          key={currentIndexItem.id}
          onClick={handleItemClick}
        >
          {makeBullet()}
          <span
            className={classNames({
              ContentName: listViewDepth && listViewDepth >= 3
            })}
          >
            {currentIndexItem.name}
          </span>
          <span className="ContentSubname"> {currentIndexItem.subname}</span>
          {makeSearchCountItems()}
        </div>
        <div>{renderChildContentItem(currentIndexItem.children)}</div>
      </div>
    )
  }

  const renderChildContentItem = (children: Index[]) => {
    return (
      <>
        {_.map(children, (child) => (
          <ContentItemComponent
            currentIndexItem={child}
            key={`ContentItem-${child.id}`}
            listViewDepth={listViewDepth ? listViewDepth + 1 : 1}
          />
        ))}
      </>
    )
  }

  return renderContentItem()
}

ContentItemComponent.defaultProps = {
  currentIndexItem: undefined,
  listViewDepth: 1
}

export default ContentItemComponent
