import React from 'react'

const TableListHeaderComponent: React.FC = () => {
  return (
    <div className="TableTitleHeaderArea">
      <div className="TableTitleHeaderText">기준서 목록</div>
    </div>
  )
}

export default TableListHeaderComponent
