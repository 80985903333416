import { isEmpty } from '@cck/common'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'

import {
  indexItemsSelector,
  selectedIndexItemAtom,
  selectedOneDepthIndexItemAtom
} from 'atoms/index/Index'
import { searchTotalKeywordAtom } from 'atoms/search/Search'
import {
  selectedStandardIdAtom,
  isOpenStandardTableListDropdownAtom
} from 'atoms/standard/Standard'
import BodyComponent from 'components/body/BodyComponent'
import HeaderComponent from 'components/header/HeaderComponent'
import LnbComponent from 'components/lnb/LnbComponent'
import TableListContentAreaComponent from 'components/tabletitle/TableListContentAreaComponent'
import findIndexItemByIds from 'components/util/IndexItemUtil'
import { Index } from 'services/standard_client'

const TableContentComponent: React.FC = () => {
  const navigate = useNavigate()
  const { standardId, oneDepthIndexItemId, indexItemId } = useParams()
  const indexItems = useRecoilValue(indexItemsSelector)
  const [selectedStandardId, setSelectedStandardId] = useRecoilState(selectedStandardIdAtom)
  const setSelectedOneDepthIndexItem = useSetRecoilState(selectedOneDepthIndexItemAtom)
  const setSelectedIndexItem = useSetRecoilState(selectedIndexItemAtom)

  const setSearchTotalKeyword = useSetRecoilState(searchTotalKeywordAtom)
  const isOpenStandardTableListDropdown = useRecoilValue(isOpenStandardTableListDropdownAtom)
  const findFirstChildIndexItem = (indexItem: Index) => {
    if (isEmpty(indexItem.children)) {
      return indexItem
    }

    return indexItem.children[0]
  }

  useEffect(
    () => {
      const oneDepthIndexItem = indexItems.index.find((item) => item.id === oneDepthIndexItemId)
      if (oneDepthIndexItem) {
        setSelectedOneDepthIndexItem(oneDepthIndexItem)
        if (indexItemId) {
          const indexItem = findIndexItemByIds(oneDepthIndexItem.children, indexItemId)
          if (indexItem) {
            setSelectedIndexItem(indexItem)
            return
          }
        }
        const firstChildIndexItem = findFirstChildIndexItem(oneDepthIndexItem)
        setSelectedIndexItem(firstChildIndexItem)
        navigate(
          ['', 'standard', selectedStandardId, oneDepthIndexItem.id, firstChildIndexItem.id].join(
            '/'
          )
        )

        return
      }

      if (standardId !== selectedStandardId) {
        return
      }

      // default
      const newOneDepthIndexItem = indexItems.index[0]
      const newIndexItem = findFirstChildIndexItem(newOneDepthIndexItem)
      if (!newIndexItem || !newOneDepthIndexItem) {
        return
      }

      setSelectedOneDepthIndexItem(newOneDepthIndexItem)
      setSelectedIndexItem(newIndexItem)
      navigate(
        ['', 'standard', selectedStandardId, newOneDepthIndexItem.id, newIndexItem.id].join('/')
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [indexItems, standardId, oneDepthIndexItemId, indexItemId]
  )

  useEffect(() => {
    // set standard id with url params
    if (standardId && (!selectedStandardId || standardId !== selectedStandardId)) {
      setSelectedStandardId(standardId)
    }

    setSearchTotalKeyword('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO
  // <SearchBarAreaComponent />
  return (
    <div className="AppRoot">
      <LnbComponent />
      <div className="MainBodyFrame">
        <HeaderComponent />
        {isOpenStandardTableListDropdown ? <TableListContentAreaComponent /> : <BodyComponent />}
      </div>
    </div>
  )
}

export default TableContentComponent
