import React from 'react'
import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom } from 'atoms/search/Search'
import SearchKeywordCountItemComponent from 'components/search/SearchKeywordCountItemComponent'
import { useSearchResultExist } from 'hook/search/useSearchResultExist'

type SearchKeywordCountListComponentProps = {
  indexItemId: string
  itemArea: string
}

const SearchKeywordCountListComponent: React.FC<SearchKeywordCountListComponentProps> = ({
  indexItemId,
  itemArea
}) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  const isSearchResultExist = useSearchResultExist(indexItemId)

  const makeSearchCountItems = () => {
    const renderComponents: JSX.Element[] = []
    if (!isSearchResultExist) {
      return renderComponents
    }
    renderComponents.push(<span> </span>)
    searchStandardCountMap.forEach((value, key) => {
      const found = value.find((searchResult) => searchResult.id === indexItemId)
      if (found) {
        renderComponents.push(
          <SearchKeywordCountItemComponent
            indexItemId={indexItemId}
            itemArea={itemArea}
            keyword={key}
          />
        )
      }
    })

    return renderComponents
  }

  return <>{makeSearchCountItems()}</>
}

export default SearchKeywordCountListComponent
