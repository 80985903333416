import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { bookmarkDuplicateAddAlertAtom } from 'atoms/bookmark/Bookmark'
import { ReactComponent as AlertIcon } from 'resource/bookmark/bookmark_alert.svg'
import { ReactComponent as DeleteIcon } from 'resource/delete/item_delete.svg'

const BookmarkDuplicateAddAlertComponent: React.FC = () => {
  const [bookmarkDuplicateAddAlert, setBookmarkDuplicateAddAlert] = useRecoilState(
    bookmarkDuplicateAddAlertAtom
  )

  useEffect(() => {
    if (bookmarkDuplicateAddAlert) {
      setTimeout(() => {
        setBookmarkDuplicateAddAlert(false)
      }, 2000)
    }
  })

  const renderBookmarkAlert = () => {
    if (!bookmarkDuplicateAddAlert) {
      return <></>
    }

    return (
      <div className="BookmarkDuplicateAddAlertFrame">
        <div className="BookmarkAlertText">
          <AlertIcon />
          <span>이미 등록된 북마크입니다.</span>
        </div>
        <div className="BookmarkAlertIcon">
          <DeleteIcon fill="#FD7E14" />
        </div>
      </div>
    )
  }

  return renderBookmarkAlert()
}

export default BookmarkDuplicateAddAlertComponent
