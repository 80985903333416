import { isEmpty } from '@cck/common'
import React, { useRef, useEffect, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import {
  bookmarkItemMapAtom,
  selectedBookmarkStandardIdAtom,
  lastAddedBookmarkNameAtom,
  BookmarkItemMap
} from 'atoms/bookmark/Bookmark'
import BookmarkOneDepthContentAreaComponent from 'components/bookmark/BookmarkOneDepthContentAreaComponent'

const BookmarkContentAreaComponent: React.FC = () => {
  const bookmarkItemMapLodable = useRecoilValueLoadable(bookmarkItemMapAtom)
  const [bookmarkItemMap, setBookmarkItemMap] = useState<BookmarkItemMap>(new Map())
  const lastAddedBookmarkName = useRecoilValue(lastAddedBookmarkNameAtom)
  const selectedBookmarkStandardId = useRecoilValue(selectedBookmarkStandardIdAtom)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (bookmarkItemMapLodable.state === 'hasValue') {
      setBookmarkItemMap(bookmarkItemMapLodable.contents)
    }
  }, [bookmarkItemMapLodable])

  useEffect(() => {
    const element = ref.current
    if (!element) {
      return
    }

    const child = element.children[0]
    if (!child) {
      return
    }

    const scrollTarget = document.getElementsByClassName(
      'Bookmark-' + selectedBookmarkStandardId + '-' + lastAddedBookmarkName.replace(/ /g, '')
    )
    if (!scrollTarget[0]) {
      return
    }

    element.scroll(0, (scrollTarget[0] as HTMLElement).offsetTop)
  })

  const makeBookmarkNoContent = () => {
    return (
      <div className="BookmarkNoContent">
        <div className="BookmarkNoContentText">저장한 북마크가 없습니다</div>
      </div>
    )
  }

  const renderBookmarkContentArea = () => {
    if (bookmarkItemMap.size === 0 || isEmpty(bookmarkItemMap.get(selectedBookmarkStandardId))) {
      return makeBookmarkNoContent()
    }

    const oneDepthBookmarkItemMap = bookmarkItemMap.get(selectedBookmarkStandardId)
    if (isEmpty(oneDepthBookmarkItemMap)) {
      return makeBookmarkNoContent()
    }

    const renderComponents: JSX.Element[] = []

    oneDepthBookmarkItemMap.forEach((bookmarkItem, key) => {
      renderComponents.push(
        <BookmarkOneDepthContentAreaComponent
          bookmarkItem={bookmarkItem}
          key={`BookmarkOneDepthContentArea-${key}`}
          oneDepthIndexId={key}
        />
      )
    })

    return renderComponents
  }

  return (
    <div className="BookmarkContent" ref={ref}>
      {renderBookmarkContentArea()}
    </div>
  )
}

export default BookmarkContentAreaComponent
