import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  selectedBookmarkStandardIdAtom,
  BookmarkItem,
  bookmarkEditingModeAtom,
  removeBookmarkItemMapSelector
} from 'atoms/bookmark/Bookmark'
import BookmarkContentComponent from 'components/bookmark/BookmarkContentComponent'
import { ReactComponent as BookmarkDeleteIcon } from 'resource/bookmark_delete.svg'

type BookmarkOneDepthContentAreaComponentProps = {
  oneDepthIndexId: string
  bookmarkItem: BookmarkItem
}

const BookmarkOneDepthContentAreaComponent: React.FC<BookmarkOneDepthContentAreaComponentProps> = ({
  bookmarkItem,
  oneDepthIndexId
}) => {
  const bookmarkEditingMode = useRecoilValue(bookmarkEditingModeAtom)
  const selectedBookmarkStandardId = useRecoilValue(selectedBookmarkStandardIdAtom)
  const removeBookmarkItemMap = useSetRecoilState(removeBookmarkItemMapSelector)

  const renderBookmarkOneDepthContents = () => {
    const renderComponents: JSX.Element[] = []
    bookmarkItem.bookmarkContentList.forEach((bookmarkContentItem) => {
      renderComponents.push(
        <BookmarkContentComponent
          bookmarkContentItem={bookmarkContentItem}
          key={`BookmarkContent-${oneDepthIndexId}-${bookmarkContentItem.id}`}
          oneDepthIndexId={oneDepthIndexId}
        />
      )
    })

    return renderComponents
  }

  const deleteBookmark = (event: React.MouseEvent) => {
    event.preventDefault()
    removeBookmarkItemMap({ standardId: selectedBookmarkStandardId, oneDepthIndexId })
  }

  const renderBookmarkDeleteIcon = () => {
    if (bookmarkEditingMode) {
      return <BookmarkDeleteIcon className="BookmarkDeleteIcon" onClick={deleteBookmark} />
    }
  }

  const renderBookmarkOneDepthName = () => {
    if (!bookmarkEditingMode) {
      return (
        <div className="BookmarkOneDepthNameFrame">
          <div className="BookmarkOneDepthName">{bookmarkItem.oneDepthIndexName}</div>
        </div>
      )
    }

    return (
      <div className="BookmarkOneDepthNameFrame">
        {renderBookmarkDeleteIcon()}
        <div className="BookmarkOneDepthName">{bookmarkItem.oneDepthIndexName}</div>
      </div>
    )
  }

  return (
    <div>
      {renderBookmarkOneDepthName()}
      {renderBookmarkOneDepthContents()}
    </div>
  )
}

export default BookmarkOneDepthContentAreaComponent
