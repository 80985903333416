import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  BookmarkContentItem,
  bookmarkEditingModeAtom,
  removeBookmarkItemMapSelector,
  selectedBookmarkStandardIdAtom
} from 'atoms/bookmark/Bookmark'
import BookmarkContentItemComponent from 'components/bookmark/BookmarkContentItemComponent'
import { ReactComponent as BookmarkDeleteIcon } from 'resource/bookmark_delete.svg'

type BookmarkContentComponentProps = {
  oneDepthIndexId: string
  bookmarkContentItem: BookmarkContentItem
}

const BookmarkContentComponent: React.FC<BookmarkContentComponentProps> = ({
  oneDepthIndexId,
  bookmarkContentItem
}) => {
  const selectedBookmarkStandardId = useRecoilValue(selectedBookmarkStandardIdAtom)
  const bookmarkEditingMode = useRecoilValue(bookmarkEditingModeAtom)
  const removeBookmarkItemMap = useSetRecoilState(removeBookmarkItemMapSelector)

  const renderBookmarkContent = () => {
    return (
      <BookmarkContentItemComponent
        contentId={bookmarkContentItem.id}
        key={`BookmarkContentItem-${selectedBookmarkStandardId}-${bookmarkContentItem.id}`}
        standardId={selectedBookmarkStandardId}
      />
    )
  }

  const deleteBookmark = (event: React.MouseEvent) => {
    event.preventDefault()
    removeBookmarkItemMap({
      standardId: selectedBookmarkStandardId,
      oneDepthIndexId,
      bookmarkName: bookmarkContentItem.bookmarkName
    })
    //
  }

  const renderBookmarkDeleteIcon = () => {
    if (bookmarkEditingMode) {
      return <BookmarkDeleteIcon className="BookmarkDeleteIcon" onClick={deleteBookmark} />
    }
  }

  const renderBookmarkContentName = () => {
    if (!bookmarkEditingMode) {
      return <div className="BookmarkContentName">{bookmarkContentItem.bookmarkName}</div>
    }

    return (
      <div className="BookmarkContentNameFrame">
        {renderBookmarkDeleteIcon()}
        <div className="BookmarkContentName">{bookmarkContentItem.bookmarkName}</div>
      </div>
    )
  }

  const makeBookmarkContentFrameClassName = () => {
    return (
      'Bookmark-' +
      selectedBookmarkStandardId +
      '-' +
      bookmarkContentItem.bookmarkName.replace(/ /g, '')
    )
  }

  return (
    <div className={`BookmarkContentFrame ${makeBookmarkContentFrameClassName()}`}>
      {renderBookmarkContentName()}
      {renderBookmarkContent()}
    </div>
  )
}

export default BookmarkContentComponent
