import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom } from 'atoms/search/Search'

type Hook = (indexItemId?: string) => boolean

export const useSearchResultExist: Hook = (indexItemId) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  if (!indexItemId || indexItemId === '') {
    return false
  }

  let isExist = false
  searchStandardCountMap.forEach((value) => {
    const found = value.find((searchResult) => searchResult.id === indexItemId)
    if (found) {
      isExist = true
      return false // break forEach loop
    }
  })

  return isExist
}
