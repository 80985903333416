import React, { useRef, useEffect, ReactNode } from 'react'
import { useRecoilState } from 'recoil'

import { bookmarkAreaOpenAtom } from 'atoms/bookmark/Bookmark'
import DescriptionAreaComponent from 'components/description/DescriptionAreaComponent'
import { ReactComponent as BookmarkOpenFloatingButton } from 'resource/bookmark/bookmark_open_floating_button.svg'

type ContentFrameComponentProps = {
  children?: ReactNode
}

const ContentFrameComponent: React.FC<ContentFrameComponentProps> = ({ children }) => {
  const [bookmarkAreaOpen, setBookmarkAreaOpen] = useRecoilState(bookmarkAreaOpenAtom)
  const contentTextAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const current = contentTextAreaRef?.current
    if (!current) {
      return
    }

    current.scrollTo(0, 0)
  })

  const handleBookmarkAreaOpen = () => {
    setBookmarkAreaOpen(true)
  }
  const makeBookmarkOpenFloatingButton = () => {
    return (
      <div className="BookmarkOpenFloatingButtonFrame" onClick={() => handleBookmarkAreaOpen()}>
        <div className="BookmarkOpenFloatingButton">
          <BookmarkOpenFloatingButton />
        </div>
        <div className="BookmarkOpenFloatingText">북마크</div>
      </div>
    )
  }

  const makeContentFrame = () => {
    if (bookmarkAreaOpen) {
      return (
        <div className="ContentFrame">
          <div className="ContentTextArea" ref={contentTextAreaRef}>
            <DescriptionAreaComponent />
          </div>
          {children}
        </div>
      )
    }

    return (
      <div className="ContentFrame">
        <div className="ContentTextArea" ref={contentTextAreaRef}>
          <DescriptionAreaComponent />
        </div>
        {makeBookmarkOpenFloatingButton()}
      </div>
    )
  }

  return makeContentFrame()
}
export default ContentFrameComponent
