import { Image } from 'antd'
import { DOMNode, Element, Text } from 'html-react-parser'
import _ from 'lodash'
import React from 'react'

function isText(node: DOMNode): node is Text {
  return node.type === 'text'
}

export function isTag(node: DOMNode): node is Element {
  return node.type === 'tag' || node.type === 'script' || node.type === 'style'
}

export function updateImageSrc(node: DOMNode): undefined | React.ReactElement {
  if (isTag(node) && node.tagName === 'img') {
    const shortName = node.attribs.src.replace(/^\(PNG (\d+-\d+).*/g, '$1')
    const src = `${process.env.PUBLIC_URL}/as_imgs/${shortName}.png`
    return <Image src={src} />
  }
  return undefined
}

export function highlightKeywords(
  searchPatterns: { keyword: string; color: string }[],
  node: DOMNode
): undefined | React.ReactElement[] {
  if (
    !isText(node) ||
    _.every(searchPatterns, (pattern) => node.nodeValue.indexOf(pattern.keyword) < 0)
  ) {
    return
  }

  const positions: { keyword: string; color: string; begin: number; end: number }[] = []
  _.map(searchPatterns, (pattern) => {
    const matchedList = node.nodeValue.matchAll(new RegExp(pattern.keyword, 'g'))
    for (const matched of matchedList) {
      if (matched.index !== undefined && matched.index >= 0) {
        positions.push({
          ...pattern,
          begin: matched.index,
          end: matched.index + pattern.keyword.length
        })
      }
    }
  })

  if (_.isEmpty(positions)) {
    return
  }

  const pos = _.sortBy(positions, (position) => position.begin)

  let begin = 0
  let end = 0
  let idx = 0
  let keyword
  const elements: React.ReactElement[] = []
  while (idx < pos.length) {
    if (end <= pos[idx].begin) {
      elements.push(<>{node.nodeValue.slice(end, pos[idx].begin)}</>)
      begin = pos[idx].begin
      keyword = pos[idx].keyword
    } else if (end > pos[idx].begin) {
      begin = end
      if (end >= pos[idx].end) {
        ++idx
        continue
      }

      keyword = node.nodeValue.slice(begin, pos[idx].end)
    }

    elements.push(<span style={{ background: pos[idx].color, padding: '0.2em 0' }}>{keyword}</span>)
    end = pos[idx].end
    ++idx
  }

  if (end < node.nodeValue.length) {
    elements.push(<>{node.nodeValue.slice(end)}</>)
  }

  return elements
}
