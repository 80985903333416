import { isEmpty } from '@cck/common'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil'

import {
  searchTotalKeywordAtom,
  searchStandardCountMapAtom,
  addSearchStandardColorMapSelector,
  selectedSearchedKeywordAtom,
  maxSearchCountAlertAtom,
  getCandidatesSelector
} from 'atoms/search/Search'
import TotalSearchAutoKeywordComponent from 'components/totalsearch/TotalSearchAutoKeywordComponent'
import { ReactComponent as SearchIcon } from 'resource/search_icon.svg'
import { searchKeyword } from 'services/standard_client'

const TotalSearchComponent: React.FC = () => {
  const [searchStandardCountMap, setSearchStandardCountMap] = useRecoilState(
    searchStandardCountMapAtom
  )
  const [searchTotalKeyword, setSearchTotalKeyword] = useRecoilState(searchTotalKeywordAtom)
  const setAddSearchStandardColorMap = useSetRecoilState(addSearchStandardColorMapSelector)
  const setSelectedSearchedKeyword = useSetRecoilState(selectedSearchedKeywordAtom)
  const [maxSearchCountAlert, setMaxSearchCountAlert] = useRecoilState(maxSearchCountAlertAtom)
  const [candidates, setCandidates] = React.useState<string[]>([])

  const getAutoCompleteKeywords = useRecoilCallback<[string], void>(
    ({ snapshot }) =>
      async (inputStr) => {
        const words = await snapshot.getPromise(getCandidatesSelector(inputStr))
        setCandidates(words)
      },
    [setCandidates]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAutoCompleteKeywords = React.useCallback(
    _.debounce((value) => getAutoCompleteKeywords(value), 300),
    [getAutoCompleteKeywords]
  )

  const handleChangeKeyword = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement

    setSearchTotalKeyword(target.value)
    debouncedAutoCompleteKeywords(target.value)
  }

  const handleKeyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchKeyword(searchTotalKeyword)
    }
  }

  const handleSearchKeyword = (searchTargetKeyword: string) => {
    if (searchTotalKeyword === '') {
      return
    }

    setCandidates([])

    if (searchStandardCountMap.size >= 6) {
      setMaxSearchCountAlert(true)
      return
    }

    setSearchTotalKeyword(searchTargetKeyword)

    searchKeyword(searchTargetKeyword).then((response) => {
      const newCountMap = _.cloneDeep(searchStandardCountMap)
      _.forEach(response.counts, ({ keyword, searchCount }) => {
        setAddSearchStandardColorMap(keyword)
        newCountMap.set(keyword, searchCount)
      })

      setSelectedSearchedKeyword(searchTotalKeyword)
      setSearchStandardCountMap(newCountMap)
    })
  }

  const handleFocus = () => {
    if (isEmpty(candidates) && searchTotalKeyword !== '') {
      debouncedAutoCompleteKeywords(searchTotalKeyword)
    }
  }

  return (
    <div className="TotalSearch">
      <input
        className={classNames({
          TotalSearchInput: true,
          TotalSearchInputAlert: maxSearchCountAlert
        })}
        placeholder="검색"
        type="text"
        value={searchTotalKeyword}
        onChange={handleChangeKeyword}
        onFocus={handleFocus}
        onKeyPress={handleKeyPressed}
      />
      <div className="TotalSearchIcon" onClick={() => handleSearchKeyword(searchTotalKeyword)}>
        <SearchIcon className="TotalSearchIconSvg" />
      </div>
      {searchTotalKeyword !== '' && (
        <TotalSearchAutoKeywordComponent
          candidatesKeywords={candidates}
          handleSearchKeyword={handleSearchKeyword}
          searchInputKeyword={searchTotalKeyword}
          setCandidates={setCandidates}
        />
      )}
    </div>
  )
}
export default TotalSearchComponent
