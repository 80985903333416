import { isEmpty } from '@cck/common'
import React from 'react'
import { useRecoilValue } from 'recoil'

import {
  searchStandardCountMapAtom,
  searchStandardColorMapAtom,
  SEARCH_TITLE_KEYWORD_COLOR_LIST,
  SEARCH_CONTENT_KEYWORD_COLOR_LIST
} from 'atoms/search/Search'

type SearchKeywordCountItemComponentProps = {
  keyword: string
  indexItemId: string
  itemArea: string
}

const SearchKeywordCountItemComponent: React.FC<SearchKeywordCountItemComponentProps> = ({
  keyword,
  indexItemId,
  itemArea
}) => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  const searchStandardColorMap = useRecoilValue(searchStandardColorMapAtom)
  const makeSearchCountItem = () => {
    if (isEmpty(searchStandardCountMap)) {
      return
    }

    const searchCount = searchStandardCountMap.get(keyword)
    if (isEmpty(searchCount)) {
      return <></>
    }

    const found = searchCount.find((searchResult) => searchResult.id === indexItemId)
    if (!found) {
      return <></>
    }

    const colorIndex = searchStandardColorMap.get(keyword)
    let color = '#FFFFFF'
    if (colorIndex !== undefined && typeof colorIndex === 'number') {
      color =
        itemArea === 'ContentItem'
          ? SEARCH_CONTENT_KEYWORD_COLOR_LIST[colorIndex]
          : SEARCH_TITLE_KEYWORD_COLOR_LIST[colorIndex]
    }

    return (
      <span className="SearchCountItem" style={{ backgroundColor: color }}>
        {found.count}
      </span>
    )
  }

  return <>{makeSearchCountItem()}</>
}

export default SearchKeywordCountItemComponent
