import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import {
  selectedBookmarkStandardIdAtom,
  bookmarkItemMapAtom,
  BookmarkItemMap
} from 'atoms/bookmark/Bookmark'
import { standardsSelector } from 'atoms/standard/Standard'
import { Standard } from 'services/standard_client'

const BookmarkTabAreaComponent: React.FC = () => {
  const bookmarkItemMapLodable = useRecoilValueLoadable(bookmarkItemMapAtom)
  const [bookmarkItemMap, setBookmarkItemMap] = useState<BookmarkItemMap>(new Map())
  const [selectedBookmarkStandardId, setSelectedBookmarkStandardId] = useRecoilState(
    selectedBookmarkStandardIdAtom
  )
  const standards = useRecoilValue(standardsSelector)

  useEffect(() => {
    if (bookmarkItemMapLodable.state === 'hasValue') {
      setBookmarkItemMap(bookmarkItemMapLodable.contents)
    }
  }, [bookmarkItemMapLodable])

  const makeStandardNameItem = (standardObject: Standard) => {
    if (standardObject.id === selectedBookmarkStandardId) {
      return (
        <div
          className="BookmarkTabStandardName BookmarkTabStandardNameSelected"
          key={`StandardName-${standardObject.id}`}
        >
          {standardObject.name}
        </div>
      )
    }
    return (
      <div
        className="BookmarkTabStandardName"
        key={`StandardName-${standardObject.id}`}
        onClick={() => setSelectedBookmarkStandardId(standardObject.id)}
      >
        {standardObject.name}
      </div>
    )
  }

  const makeBookmarkTabItems = () => {
    const components: JSX.Element[] = []
    bookmarkItemMap.forEach((value, key) => {
      const found = standards.find((standardObject) => standardObject.id === key)
      if (found) {
        components.push(makeStandardNameItem(found))
      }
    })

    return components
  }

  const makeBookmarkTabArea = () => {
    if (bookmarkItemMap.size === 0 || selectedBookmarkStandardId === '') {
      return <></>
    }

    return <div className="BookmarkTabFrame">{makeBookmarkTabItems()}</div>
  }

  return makeBookmarkTabArea()
}

export default BookmarkTabAreaComponent
