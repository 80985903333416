import React from 'react'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import SearchKeywordListComponent from 'components/search/SearchKeywordListComponent'
import StandardListComponent from 'components/standardlist/StandardListComponent'
import TotalSearchComponent from 'components/totalsearch/TotalSearchComponent'

const LnbComponent: React.FC = () => {
  const setSelectedOneDepthIndexItem = useSetRecoilState(selectedOneDepthIndexItemAtom)
  return (
    <>
      <div className="Lnb">
        <div className="LnbTopFrame">
          <div className="LnbTopLogoFrame">
            <div className="LnbLogo">
              <Link
                className="LnbLogoText"
                to="/"
                onClick={() => {
                  setSelectedOneDepthIndexItem(undefined)
                }}
              >
                비영리DB
              </Link>
            </div>
          </div>
          <div className="LnbStandardListFrame">
            <StandardListComponent />
          </div>
        </div>
        <TotalSearchComponent />
        <SearchKeywordListComponent />
        <div className="LnbBanner">
          <a href="https://cafe.daum.net/youngcpa2012">청년공인회계사회</a>
        </div>
      </div>
    </>
  )
}

export default LnbComponent
