import { isEmpty } from '@cck/common'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { searchStandardCountMapAtom, maxSearchCountAlertAtom } from 'atoms/search/Search'
import SearchKeywordItemComponent from 'components/search/SearchKeywordItemComponent'
import { ReactComponent as MaxSearchAlertIcon } from 'resource/search/max_search_alert.svg'

const SearchKeywordListComponent: React.FC = () => {
  const searchStandardCountMap = useRecoilValue(searchStandardCountMapAtom)
  const maxSearchCountAlert = useRecoilValue(maxSearchCountAlertAtom)

  const makeMaxSearchAlert = () => {
    return (
      <div className="MaxSearchAlertFrame">
        <div className="MaxSearchAlertIcon">
          <MaxSearchAlertIcon />
        </div>
        <div className="MaxSearchAlertText">
          검색어는 최대 6개까지 검색할 수 있습니다. 기존 검색어를 삭제 후 진행해 주세요.
        </div>
      </div>
    )
  }

  const renderKeywordListItem = () => {
    if (isEmpty(searchStandardCountMap)) {
      return null
    }

    const components: JSX.Element[] = []
    if (maxSearchCountAlert) {
      components.push(makeMaxSearchAlert())
    }

    searchStandardCountMap.forEach((value, key) => {
      components.push(<SearchKeywordItemComponent keyword={key} />)
    })

    return components
  }

  return <div className="SearchResultFrame">{renderKeywordListItem()}</div>
}
export default SearchKeywordListComponent
