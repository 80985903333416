import React from 'react'

import TableTitleColumnAreaComponent from 'components/tabletitle/TableTitleColumnAreaComponent'

const TableListContentAreaComponent: React.FC = () => {
  const renderTableColumns = () => {
    const columnComponents: JSX.Element[] = []
    for (let order = 0; order < 4; order += 1) {
      columnComponents.push(
        <TableTitleColumnAreaComponent columnOrder={order} key={`TableTitleColumn-${order}`} />
      )
    }

    return columnComponents
  }

  return <div className="TableTitleContentArea">{renderTableColumns()}</div>
}

export default TableListContentAreaComponent
