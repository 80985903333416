import _ from 'lodash'
import React from 'react'

import ContentItemComponent from 'components/content/ContentItemComponent'
import { Index } from 'services/standard_client'

type ContentItemListComponentProps = {
  oneDepthIndexItem?: Index
}

const ContentItemListComponent: React.FC<ContentItemListComponentProps> = ({
  oneDepthIndexItem
}) => {
  const renderChildContentItem = (children?: Index[]) => {
    if (!children) {
      return null
    }
    return (
      <>
        {_.map(children, (child) => (
          <ContentItemComponent
            currentIndexItem={child}
            key={`ContentItem-${child.id}`}
            listViewDepth={2}
          />
        ))}
      </>
    )
  }

  return renderChildContentItem(oneDepthIndexItem?.children)
}

ContentItemListComponent.defaultProps = {
  oneDepthIndexItem: undefined
}

export default ContentItemListComponent
