import { LinearProgress } from '@mui/material'
import React, { useEffect, Suspense } from 'react'
import { useParams } from 'react-router'
import { useRecoilState } from 'recoil'

import { selectedStandardIdAtom } from 'atoms/standard/Standard'
import TableListComponent from 'components/TableListComponent'
import LnbComponent from 'components/lnb/LnbComponent'

const MainTableListComponent: React.FC = () => {
  const { standardId } = useParams()
  const [selectedStandardId, setSelectedStandardId] = useRecoilState(selectedStandardIdAtom)

  useEffect(
    () => {
      if (standardId && (!selectedStandardId || standardId !== selectedStandardId)) {
        setSelectedStandardId(standardId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className="AppRoot">
      <LnbComponent />
      <Suspense fallback={<LinearProgress className="linear_progress" />}>
        <TableListComponent />
      </Suspense>
    </div>
  )
}

export default MainTableListComponent
