import 'dotenv/config'

let backendHost: string
const envBackendHost = process.env.REACT_APP_BACKENDHOST
if (envBackendHost) {
  // real
  backendHost = envBackendHost
} else {
  // dev
  const hostRegex = /^(http(?:s)?:\/\/[a-z0-9.]+)[:/]/
  const matched = window.location.href.match(hostRegex)
  if (!matched) {
    backendHost = 'http://localhost:8080'
  } else {
    backendHost = matched[1] + ':8080'
  }
}

async function sendRequest(url: string, body: unknown) {
  try {
    const result = await fetch(`${backendHost}/${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return await result.json()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Failed to post request', url, JSON.stringify(body), error)
  }
}

export interface Standard {
  id: string
  name: string
}

export interface StandardsReply {
  standards: Standard[]
}

export async function getStandards(): Promise<StandardsReply> {
  return (await sendRequest('v1/getStandards', {})) as StandardsReply
}

export interface Index {
  id: string
  name: string
  subname: string
  children: Index[]
}

export interface IndexReply {
  index: Index[]
}

export async function getIndex(standardId: string): Promise<IndexReply> {
  return (await sendRequest('v1/getIndex', { standardId })) as IndexReply
}

export interface Content {
  id: string
  name: string
  content: string
  bookmarkable: boolean
}

export interface ContentReply {
  content: Content[]
}

export async function getContent(standardId: string, id: string): Promise<ContentReply> {
  return (await sendRequest('v1/getContent', { standardId, id })) as ContentReply
}

export interface SearchCount {
  standardId: string
  id: string
  count: number
}

export interface SearchKeywordCount {
  keyword: string
  searchCount: SearchCount[]
}

export interface SearchReply {
  counts: SearchKeywordCount[]
}

export async function searchKeyword(keyword: string): Promise<SearchReply> {
  return (await sendRequest('v1/searchKeyword', { keyword })) as SearchReply
}

export interface BookmarkIds {
  standardId: string
  ids: string[]
}

export async function sortIds(standardId: string, ids: string[]): Promise<BookmarkIds> {
  return (await sendRequest('v1/sortIds', { standardId, ids })) as BookmarkIds
}

export interface AutocompleteReply {
  keywords: string[]
}

export async function loadAutoCompleteKeywords(): Promise<AutocompleteReply> {
  return (await sendRequest('v1/loadAutoCompleteKeywords', {})) as AutocompleteReply
}
