import { LinearProgress } from '@mui/material'
import React, { Suspense } from 'react'

import BookmarkContentAreaComponent from 'components/bookmark/BookmarkContentAreaComponent'
import BookmarkHeaderComponent from 'components/bookmark/BookmarkHeaderComponent'
import BookmarkTabAreaComponent from 'components/bookmark/BookmarkTabAreaComponent'
import { ReactComponent as BookmarkResizeIconVertical } from 'resource/bookmark_resize_icon_vertical.svg'

const VerticalBookmarkAreaComponent: React.FC = () => {
  return (
    <div className="VerticalBookmarkFrame">
      <div className="VerticalBookmarkResizeHandle">
        <BookmarkResizeIconVertical />
      </div>
      <Suspense fallback={<LinearProgress className="linear_progress" />}>
        <div className="VerticalBookmarkArea">
          <BookmarkHeaderComponent />
          <BookmarkTabAreaComponent />
          <BookmarkContentAreaComponent />
        </div>
      </Suspense>
    </div>
  )
}

export default VerticalBookmarkAreaComponent
