import _ from 'lodash'
import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  searchStandardCountMapAtom,
  searchStandardColorMapAtom,
  SEARCH_DELETE_ICON_COLOR_LIST,
  SEARCH_CONTENT_KEYWORD_COLOR_LIST,
  maxSearchCountAlertAtom
} from 'atoms/search/Search'
import { ReactComponent as DeleteIcon } from 'resource/delete/item_delete.svg'

type SearchKeywordItemComponentProps = {
  keyword: string
}

const SearchKeywordItemComponent: React.FC<SearchKeywordItemComponentProps> = ({ keyword }) => {
  const [searchStandardCountMap, setSearchStandardCountMap] = useRecoilState(
    searchStandardCountMapAtom
  )
  const [searchStandardColorMap, setSearchStandardColorMap] = useRecoilState(
    searchStandardColorMapAtom
  )
  const setMaxSearchCountAlert = useSetRecoilState(maxSearchCountAlertAtom)

  const handleDeleteItem = () => {
    const newCountMap = _.cloneDeep(searchStandardCountMap)
    const newColorMap = _.cloneDeep(searchStandardColorMap)
    newCountMap.delete(keyword)
    newColorMap.delete(keyword)
    setSearchStandardCountMap(newCountMap)
    setSearchStandardColorMap(newColorMap)

    setMaxSearchCountAlert(false)
  }

  const colorIndex = searchStandardColorMap.get(keyword)
  let backgroundColor = '#FFFFFF'
  let deleteIconColor = '#FFFFFF'
  if (colorIndex !== undefined && typeof colorIndex === 'number') {
    backgroundColor = SEARCH_CONTENT_KEYWORD_COLOR_LIST[colorIndex]
    deleteIconColor = SEARCH_DELETE_ICON_COLOR_LIST[colorIndex]
  }

  return (
    <div className="SearchKeywordItem" style={{ backgroundColor, borderColor: deleteIconColor }}>
      <span>{keyword}</span>
      <span className="SearchKeywordItemDelete" onClick={handleDeleteItem}>
        <DeleteIcon fill={deleteIconColor} />
      </span>
    </div>
  )
}

SearchKeywordItemComponent.defaultProps = {
  keyword: undefined
}

export default SearchKeywordItemComponent
