import React from 'react'
import { useRecoilValue } from 'recoil'

import { selectedOneDepthIndexItemAtom } from 'atoms/index/Index'
import SearchKeywordCountListComponent from 'components/search/SearchKeywordCountListComponent'

const HeaderComponent: React.FC = () => {
  const selectedOneDepthIndexItem = useRecoilValue(selectedOneDepthIndexItemAtom)

  const makeSearchCountItems = () => {
    if (!selectedOneDepthIndexItem) {
      return null
    }

    return (
      <SearchKeywordCountListComponent
        indexItemId={selectedOneDepthIndexItem.id}
        itemArea="TitleItem"
      />
    )
  }

  return (
    <div className="TableTitleHeaderArea">
      <div className="TableTitleHeaderIdText">
        {selectedOneDepthIndexItem && selectedOneDepthIndexItem.id}
      </div>
      <div className="TableTitleHeaderText">
        {selectedOneDepthIndexItem && selectedOneDepthIndexItem.name}
      </div>
      {makeSearchCountItems()}
    </div>
  )
}

export default HeaderComponent
